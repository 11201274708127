import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { reportTypeDesc } from 'app-modules/core/consts/report-type';
import { ScheduledReportParams } from 'app-modules/core/models/scheduled-report-params.model';
import { PreferenceService } from 'app-modules/core/services/preference.service';
import { ScheduleReportService } from 'app-modules/core/store/schedule-report/schedule-report.service';
import { TimePeriodService } from 'app-modules/core/store/time-period/time-period.service';
import { TripReportParams } from 'app-modules/reports/models/trip-report.model';
import { TKeyValuePair, TripState } from 'emr-ng-shared';
import { DateTimeObject, EmrUtilService, ReportType, ScheduleReportRequest, TimePeriod, TimePeriodInfo, UserSettingsType } from 'emr-ng-shared';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-trip-report',
  templateUrl: './trip-report.component.html',
  styleUrls: ['./trip-report.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TripReportComponent implements OnInit, OnDestroy {
  @ViewChild(NgForm, { static: true }) frmTripReport: NgForm;

  @Input() selectedReportType: ReportType;
  reportParams = new TripReportParams();
  dateTimeObject = new DateTimeObject();
  dateTimePreferenceSubscription: Subscription;
  public isDateRangeValid = true;

  public isFormSubmitted = false;

  private _validate = false;
  tripStateList: any[];
  @Input() public set validate(val: any) {
    this.reValidationForm();
    this.isFormSubmitted = !val?.validate;
    this._validate = val;
  };
  public get validate(): any {
    return this._validate;
  }

  @Input()
  set bindEditParams(val: TKeyValuePair[]) {
    this.bindFormReportParams(val);
  }

  minDate: Date = new Date(2008, 0, 1);
  maxDate: Date = new Date(9999, 11, 31);
  public periodList: TimePeriodInfo[];
  private periodListSubscription: Subscription;

  @Input() public isNewScheduleReport = false;

  @Output() reportRequestParamsChange = new EventEmitter();
  reportRequest: ScheduleReportRequest;
  @Input() set reportRequestParams(val) {
    this.reportRequest = val;
  }

  constructor(
    private prefSvc: PreferenceService,
    private renderer: Renderer2,
    public periodSvc: TimePeriodService,
    private utilSvc: EmrUtilService,
    public datepipe: DatePipe,
    public reportSvc: ScheduleReportService,
  ) {

    this.renderer.addClass(document.body, 'bg-trip-report');
  }

  ngAfterViewInit() {
    this.initParams();
  }

  ngOnInit() {
    this.dateTimePreferenceSubscription = this.prefSvc.getDateTimeObject().subscribe(k => {
      this.dateTimeObject = k;
    });
    this.periodListSubscription = this.periodSvc.reportTimePeriodList$.subscribe(n => {
      this.onPeriodListSubscription(n.list);
      this.initParams();
    });

    if (this.selectedReportType === ReportType.TripStopSummaryExtendedReport ||
      this.selectedReportType === ReportType.TripStopThresholdSummaryReport
    ) {
      this.tripStateList = this.reportSvc.reportTripStates;
      if (this.isNewScheduleReport) {
        this.reportParams.TripState = TripState.InProgress;
        this.initParams();
      }
    }
  }

  initParams() {
    if (this.isNewScheduleReport) {
      this.reportParams.TimePeriod = TimePeriod.Last30Days;
      this.ParamsChange(true);
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'bg-trip-report');
    if (this.dateTimePreferenceSubscription) {
      this.dateTimePreferenceSubscription.unsubscribe();
    }
    if (this.periodListSubscription) {
      this.periodListSubscription.unsubscribe();
    }
  }

  onPeriodChange() {
    this.reValidationForm();
    this.isFormSubmitted = false;
    if (this.reportParams.TimePeriod !== TimePeriod.SpecificRange) {
      this.reportParams.FromDate = null;
      this.reportParams.ThruDate = null;

      this.frmTripReport.controls['txtStartDate']?.clearValidators();
      this.frmTripReport.controls['txtEndDate']?.clearValidators();
      this.isDateRangeValid = true;
    } else {
      this.isDateRangeValid = false;
    }
    this.ParamsChange(true);
  }

  ParamsChange(resetSubject = false) {
    const request = this.getScheduledReportParams(resetSubject);
    this.reValidationForm();
    this.reportRequestParamsChange.emit(request);
  }

  public getScheduledReportParams(resetSubject = false): ScheduledReportParams {
    this.reValidationForm();
    let requestParams = new ScheduledReportParams();
    requestParams.ReportParams = [];
    requestParams.IsValid = this.frmTripReport.valid && this.isDateRangeValid;
    if (resetSubject) {
      requestParams.EmailSubject = reportTypeDesc[this.selectedReportType];
    }

    if (this.reportParams.TimePeriod === TimePeriod.SpecificRange) {
      if (this.reportParams.FromDate && this.reportParams.ThruDate) {
        let dateFormat = this.dateTimeObject ? (this.dateTimeObject?.dateFormatString + ', ' + (this.dateTimeObject.showMeridian ? 'h:mm a' : 'HH:mm')) : 'MM/dd/yyyy, h:mm a';

        if (resetSubject) {
          requestParams.EmailSubject = requestParams.EmailSubject + ': ' +
            this.utilSvc.DateFormatLocaleChange(this.reportParams.FromDate, dateFormat) + ' - ' +
            this.utilSvc.DateFormatLocaleChange(this.reportParams.ThruDate, dateFormat);
        }
        requestParams.ReportParams.push({ Key: "ReportFromDate", Value: this.utilSvc.DateFormatLocaleChange(this.reportParams.FromDate), Type: UserSettingsType.DateTime });
        requestParams.ReportParams.push({ Key: "ReportThruDate", Value: this.utilSvc.DateFormatLocaleChange(this.reportParams.ThruDate), Type: UserSettingsType.DateTime });
      }
    }
    else {
      let timePeriodDisplayName = this.periodList?.find(p => p.Period === this.reportParams.TimePeriod)?.DisplayName;
      if (resetSubject) {
        requestParams.EmailSubject = requestParams.EmailSubject + (timePeriodDisplayName ? ' - ' + timePeriodDisplayName : '');
      }
      requestParams.ReportParams.push({ Key: "NamedDateRange", Value: this.reportParams?.TimePeriod?.toString() });
    }

    switch (this.selectedReportType) {
      case ReportType.TripStopSummaryExtendedReport:
        const tripState = this.reportParams?.TripState?.toString() === '' ? null : this.reportParams?.TripState?.toString();
        requestParams.ReportParams.push({ Key: "tripStateCode", Value: tripState });
        requestParams.ReportParams.push({ Key: "DeviceIds", Value: [] });
        requestParams.ReportParams.push({ Key: "fileName", Value: "TripStopSummaryExtendedReport" });
        break;
      case ReportType.TripStopThresholdSummaryReport:
        const selectedTripState = this.reportParams?.TripState?.toString() === '' ? null : this.reportParams?.TripState?.toString();
        requestParams.ReportParams.push({ Key: "tripStateCode", Value: selectedTripState });
        requestParams.ReportParams.push({ Key: "DeviceIds", Value: [] });
        requestParams.ReportParams.push({ Key: "fileName", Value: "TripStopThresholdSummaryReport" });
        requestParams.ReportParams.push({ Key: "ExportToXSLX", Value: true });
        break;
      case ReportType.TripEndSummary:
        requestParams.ReportParams.push({ Key: "currentStartLocationDescriptions", Value: null });
        requestParams.ReportParams.push({ Key: "currentEndLocationDescriptions", Value: null });
        requestParams.ReportParams.push({ Key: "currentVehicleDescriptions", Value: null });
        requestParams.ReportParams.push({ Key: "DeviceIds", Value: [] });
        requestParams.ReportParams.push({ Key: "IsExpandAll", Value: false });
        requestParams.ReportParams.push({ Key: "GroupBy", Value: '' });
        break;
      case ReportType.ComprehensiveTripReport:
        requestParams.ReportParams.push({ Key: "fileName", Value: "ComprehensiveTripReport" });
        break;
      case ReportType.InboundOutboundReport:
        requestParams.ReportParams.push({ Key: "DeviceIds", Value: [] });
        break;
    }

    return requestParams;
  }

  onTripStateChange() {
    this.ParamsChange();
  }

  public onDateChanged() {
    if (this.reportParams.FromDate && isNaN(this.reportParams.FromDate.getTime())) {
      this.reportParams.FromDate = new Date(this.minDate);
    }

    if (this.reportParams.ThruDate && isNaN(this.reportParams.ThruDate.getTime())) {
      this.reportParams.ThruDate = new Date(this.maxDate);
    }

    this.isDateRangeValid = !((this.reportParams.ThruDate != null && this.reportParams.FromDate != null)
      && ((this.reportParams.FromDate >= this.reportParams.ThruDate)));

    this.ParamsChange(true);
  }

  private reValidationForm = () => {
    for (const key in this.frmTripReport?.controls) {
      this.frmTripReport.controls[key].markAsDirty();
      this.frmTripReport.controls[key].updateValueAndValidity();
    }
  }

  onPeriodListSubscription(periodList: TimePeriodInfo[]) {
    this.periodList = periodList;
  }

  bindFormReportParams(val: TKeyValuePair[]) {
    if (this.isNewScheduleReport || !val || val.length <= 0) { return; }

    const namedDateRange = val.find(kv => kv.Key === "NamedDateRange");
    if (namedDateRange) {
      if (isNaN(parseInt(namedDateRange.Value))) {
        this.reportParams.TimePeriod = parseInt(TimePeriod[namedDateRange.Value]);
      } else {
        this.reportParams.TimePeriod = parseInt(namedDateRange.Value);
      }
    } else {
      this.reportParams.TimePeriod = TimePeriod.SpecificRange;
      const fromParam = val.find(kv => kv.Key === "ReportFromDate");
      const thruParam = val.find(kv => kv.Key === "ReportThruDate");
      this.reportParams.FromDate = new Date(Date.parse(fromParam?.Value));
      this.reportParams.ThruDate = new Date(Date.parse(thruParam?.Value));
    }


    switch (this.selectedReportType) {
      case ReportType.TripStopSummaryExtendedReport:
      case ReportType.TripStopThresholdSummaryReport:
        this.reportParams.TripState = parseInt(val.find(kv => kv.Key === "tripStateCode")?.Value);
        break;
    }

    setTimeout(() => {
      this.ParamsChange();
    }, 500);
  }
}
