
import { Component, EventEmitter, Output, Input, ViewChild, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { LocationService } from 'app-modules/core/services/location.service';
import { CreateShipmentService } from 'app-modules/core/services/create-shipment.service';
import {
    SetLocationRequest, Address, LocationInfo, SetLocationResponse, DualVisibilityLinkCustomer,
    IMapOptions, MapTypeId, IBox, IMarkerOptions, LocationColor, ILatLong, IDrawOptions, PolygonType,
    GeofenceType, DistanceUnits, DeleteGeofenceRequest, PolygonEventArgs,
    EmrUtilService,
    BusinessRuleType
} from 'emr-ng-shared';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IErrorInfo } from 'app-modules/core/models/error-info.interface';
import { NgForm } from '@angular/forms';
import { CreateShipmentStateService } from 'app-modules/core/store/create-shipment/create-shipment-state.service';
import { map, switchMap, take } from 'rxjs/operators';
import { Subscription, of, Observable } from 'rxjs';
import { APP_IN_CHINA } from 'src/regionFinder';
import { LocationTypeCodes } from 'app-modules/core/consts/location-type.model';
import { LinkedCustomersService } from 'app-modules/core/store/create-shipment/linked-customers/linked-customer.service';
import { AuthService } from 'app-modules/core/store/auth/auth.service';
import { PreferenceService } from 'app-modules/core/services/preference.service';
import { dist_km, dist_miles, label_delete_geofence, label_save_geofence, maximum_geo_fence, geo_fence } from 'app-modules/core/consts/localization';
import { ImageMapService } from 'app-modules/core/services/image-map.service';
import { SearchAddressComponent, ValidateLocationEventArgs } from '../address-lookup/search-address/search-address.component';
import { FindAddressRequest } from '../address-lookup/models/find-address-request';
import { ErrorMessageResponseComponent, EventArgs } from '../error-message-response/error-message-response.component';
import { CustomersService } from 'app-modules/core/services/customer.service';
import { BusinessRulesService } from 'app-modules/core/store/business-rules/business-rules.service';

export class AddLocationEventArgs {
    request: SetLocationRequest;
    context?: any;
    reOpenModal?: boolean
}

@Component({
    selector: 'app-emr-location',
    templateUrl: './location.component.html',
    styleUrls: ['./location.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class LocationComponent implements OnInit {
    @ViewChild(NgForm) locationForm: NgForm;
    passShipperCustomerID: boolean = null;
    public error: IErrorInfo;
    public inChina = false;
    isChinaAddress: boolean = false;
    locationColorList$: Observable<LocationColor[]>;
    // isAddressSelected = false;
    isMapReady = false;
    isEditLocation = false;
    isFromManageLocations = false;
    newLocRequest = new FindAddressRequest();
    drawOptions: IDrawOptions;
    boundary: ILatLong[] = []
    radiusKm: string;
    copiedFromCustomerId: number = null;

    @Output() addLocation = new EventEmitter<AddLocationEventArgs>();

    locationTypeCodes = LocationTypeCodes;
    linkedShippersPushList$: Observable<DualVisibilityLinkCustomer[]>;
    isLocusAdmin = false;
    authStateSub: Subscription;

    @Input() locationTypes = [];
    context: any;
    nearestLocations: LocationInfo[];
    shownearestLocations = false;
    selectedIndex = null;

    controlColumns = 9;

    locationType = '';
    fenceType = PolygonType;
    request = new SetLocationRequest();
    newLocationRequest: SetLocationRequest;
    distanceUnits: string;
    createLocationSubscription: Subscription;
    preferenceSubscription: Subscription;
    showExistingFence = false;
    showEditableFence = false;

    // map
    public options: IMapOptions = {
        disableBirdseye: false,
        disableStreetside: false,
        navigationBarMode: 1,
        mapTypeId: MapTypeId.road
    };

    private defaultBox = this.custSvc?.defaultBox;


    locationMarker: IMarkerOptions[] = [];
    deleteGeofenceSubscription: Subscription;
    saveGeofenceSubscription: Subscription;

    public box: IBox = this.defaultBox;
    // private changeAddressModalRef: BsModalRef;
    displayChangeAddress: boolean;
    tempDrawOptions: IDrawOptions;
    setGeofence = false;
    ExistingGeofence: boolean;
    isLocusUserSupportAdmin: boolean = false;
    disableSaveButton: boolean;
    disableApplyButton: boolean;
    // Maximum number of posts can draw
    maxFencePost: number = 15;
    locationList: LocationInfo[];
    locationListSubscription: Subscription;
    businessRulesListSubscription: Subscription;
    DisplayLocationTypeBusinessRule: boolean = false;

    constructor(
        private businessruleSvc: BusinessRulesService,
        private locationSvc: LocationService,
        private createSvc: CreateShipmentService,
        private modalSvc: BsModalService,
        public bsModalRef: BsModalRef,
        private createStateSvc: CreateShipmentStateService,
        private linkedCustSvc: LinkedCustomersService,
        private authSvc: AuthService,
        private prefSvc: PreferenceService,
        private imgSvc: ImageMapService,
        private custSvc: CustomersService,
        private utilSvc: EmrUtilService,
        @Inject(APP_IN_CHINA) private isAppInChina: boolean) {
        this.inChina = isAppInChina;
        this.isChinaAddress = this.inChina;
    }

    ngOnInit() {
        this.businessRulesListSubscription = this.businessruleSvc.businessRulesList$.subscribe(k => {
            if (k.list && k.list.length > 0) {
                this.DisplayLocationTypeBusinessRule = k.list.find(br => br.BusinessRuleTypeCode ===
                    BusinessRuleType.LocationTypeRequired)?.Enabled;
            }
        });
        this.locationColorList$ = this.locationSvc.locationColorList$.pipe(map(n => n.list));
        this.linkedShippersPushList$ = this.linkedCustSvc.linkedShipperPushList$.pipe(map(n =>
            n.list.filter(itm => itm.ShipperCustomerActive ||
                itm.ShipperCustomerID === this.request.ShipperCustomerID)));
        this.isLocusAdmin = false;
        this.authStateSub = this.authSvc.authState$.pipe(take(1)).subscribe(au => {
            this.isLocusAdmin = au ? au.isEmersonAdmin : false;
            this.isLocusUserSupportAdmin = au ? au.isLocusUserSupportAdmin : false;
        });

        this.preferenceSubscription = this.prefSvc.preference$.pipe(take(1)).subscribe(n => {
            this.distanceUnits = (n?.distanceUnits.toLowerCase() === "m" || n?.distanceUnits.toLowerCase() === "miles") ? dist_miles : dist_km;
        });
        this.showMarker(this.request);
        this.disableSaveButton = true;
        this.locationListSubscription = this.locationSvc.locationList$.subscribe(k => {
            this.locationList = k.list;
        })
    }

    ngOnDestroy() {
        if (this.authStateSub) {
            this.authStateSub.unsubscribe();
        }
        if (this.preferenceSubscription) {
            this.preferenceSubscription.unsubscribe();
        }

        this.deleteGeofenceSubscription?.unsubscribe();
        this.saveGeofenceSubscription?.unsubscribe();
        this.locationListSubscription?.unsubscribe();
        this.businessRulesListSubscription?.unsubscribe();
    }

    onLocationTypeChange() {
        this.request.IsOrigin = (this.locationType === 'Origin' || this.locationType === 'Both');
        this.request.IsDestination = (this.locationType === 'Destination' || this.locationType === 'Both');
    }
    checkChinaAddress() {
        this.isChinaAddress = !this.isChinaAddress;
    }

    onAddLocation() {
        if (this.locationForm.valid) {
            this.error = null;
            this.createLocationSubscription = this.createStateSvc.selectedLinkedCustomer$.pipe(
                take(1),
                map(n => {
                    if (n && n.CustomerId) {
                        this.request.LinkedCustomerId = n.CustomerId;
                        this.request.PassShipperCustomerID = this.passShipperCustomerID;
                        this.request.DvShipperCustomerFilter = this.context;
                    }
                    if (!this.isEditLocation && !this.request.LocationId && !this.request.RadiusKm) {
                        this.request.Boundary = this.boundary?.map(n => {
                            return {
                                Latitude: n.latitude,
                                Longitude: n.longitude
                            }
                        });

                    } else if (this.drawOptions?.boundaryLocation) {
                        this.request.Boundary = this.drawOptions.boundaryLocation.map(n => {
                            return {
                                Latitude: n.latitude,
                                Longitude: n.longitude
                            }
                        });
                    }
                    if (this.request.RadiusKm) {
                        this.request.Boundary = null;
                    } else {
                        this.request.RadiusKm = null;
                    }
                    // If shipper customer id not selected then setting shipper customerId to 0 while editing
                    if (this.isEditLocation&& !this.request.ShipperCustomerID) {
                        this.request.ShipperCustomerID = 0;
                    }
                    return this.request;
                }),
                switchMap(n => {
                    if (!this.selectedIndex) {
                        if (!this.request.LocationId && (!this.shownearestLocations || this.selectedIndex == null)) {
                            n.CheckForDuplicates = true;
                        } else {
                            n.CheckForDuplicates = false;
                        }
                        if (!this.isEditLocation && n && n.LinkedCustomerId) {
                            return this.createSvc.createShipmentLocation(n);
                        }
                        n.Collisions = null;
                        return this.locationSvc.createLocation(n, this.copiedFromCustomerId);
                    } else {
                        const response: SetLocationResponse = new SetLocationResponse();
                        response.LocationId = this.nearestLocations[this.selectedIndex - 1].LocationId;
                        return of(response);
                    }
                }
                ))
                .subscribe(
                    n => {
                        if (n.LocationId && n.LocationId > 0) {
                            this.addLocationSuccess(n.LocationId);
                        } else if (n.LocationList && n.LocationList.length > 0) {
                            this.shownearestLocations = true;
                            this.nearestLocations = n.LocationList;
                        }
                    },
                    (e: IErrorInfo) => this.addLocationFailure(e)
                );
            this.selectedIndex = null;
        }
    }

    addLocationSuccess(id: number, reOpenModal = false) {
        this.request.LocationId = id;
        this.addLocation.emit({
            request: this.request,
            context: this.context,
            reOpenModal: reOpenModal
        });
        this.createLocationSubscription?.unsubscribe();
    }

    addLocationFailure(error: IErrorInfo) {
        this.error = error;
        this.createLocationSubscription?.unsubscribe();
    }

    onAddressSelected(address: Address) {
        if (this.isFromManageLocations) {
            this.onGeofenceTypeChange();
        }

        this.request.FullAddress = address.FormattedAddress;
        this.request.Address1 = address.AddressLine1;
        this.request.City = address.City;
        this.request.State = address.StateDescription;
        this.request.ZipCode = address.PostalCode;
        this.request.Country = address.CountryCode;
        this.request.Latitude = address.Latitude;
        this.request.Longitude = address.Longitude;

        if (this.isFromManageLocations) {
            this.showMarker(this.request);
        }
    }

    showMarker(location: SetLocationRequest) {
        if (this.validateLocation(location.Latitude, location.Longitude)) {
            return;
        }

        this.setMarker(location.Latitude, location.Longitude);
    }

    private IsLocationValid() {
        return this.validateLocation(this.request.Latitude, this.request.Longitude);
    }

    private validateLocation(latitude: number, longitude: number) {
        return !(latitude !== undefined && latitude !== null &&
            (latitude >= -90 && latitude <= 90) &&
            longitude !== undefined && longitude !== null &&
            (longitude >= -180 && longitude <= 180));
    }

    setMarker(latitude: number, longitude: number, skipZoom = false) {
        if (!skipZoom) {
            this.setMapBox(latitude, longitude, this.boundary);
        }
        this.setMarkerOptions(latitude, longitude);
    }

    private setMapBox(latitude: number, longitude: number, boundary: ILatLong[]) {
        const minLatBoxPadding = 0.005;
        const minLongBoxPadding = 0.005;
        const box = this.utilSvc.getNewBoxParams();
        this.utilSvc.updateBoxParams(box, latitude, longitude);
        if (boundary?.length > 0) {
            boundary.forEach((loc) => {
                this.utilSvc.updateBoxParams(box, loc.latitude, loc.longitude);
            });
        }
        this.box = {
            maxLatitude: box.maxLatitude + minLatBoxPadding,
            maxLongitude: box.maxLongitude + minLongBoxPadding,
            minLatitude: box.minLatitude - minLatBoxPadding,
            minLongitude: box.minLongitude - minLongBoxPadding
        };
    }

    ResetSavedGeofence() {
        this.boundary = null;
        this.drawOptions = null;
        this.ExistingGeofence = false;
    }

    private setMarkerOptions(latitude: number, longitude: number) {
        const location = {
            latitude: latitude,
            longitude: longitude
        };
        const locationMarker = {
            id: this.request.LocationId?.toString(),
            position: location,
            icon: this.imgSvc.getImageURL('y', '', ''),
            draggable: this.displayChangeAddress
        };
        this.locationMarker = [locationMarker];
        if (this.boundary?.length > 0 || (Number(this.radiusKm) > 0)) {
            this.ExistingGeofence = true;
            this.showExistingFence = true;
            if (this.boundary?.length > 0) {
                this.drawOptions = {
                    strokeColor: 'blue',
                    boundaryLocation: this.boundary,
                    polygonType: PolygonType.FreeFlow
                };
            } else {
                this.drawOptions = {
                    center: location,
                    radius: +this.request.RadiusKm,
                    strokeColor: 'blue',
                    polygonType: PolygonType.Circle,
                    units: (this.distanceUnits.toLowerCase() === "miles" || this.distanceUnits.toLowerCase() === "m") ? DistanceUnits.Miles : DistanceUnits.Kilometers
                };
            }
        }
    }

    onMapClick(e) {
        if (this.displayChangeAddress && e?.location) {
            this.setMarker(e.location.latitude, e.location.longitude, true);
            this.onMarkerLocationChanged(e.location);
        }
    }

    OnMarkerDragEnd(e) {
        if (this.displayChangeAddress && e?.Location) {
            this.setMarker(e.Location.latitude, e.Location.longitude, true);
            this.onMarkerLocationChanged(e.Location);
        }
    }

    onMarkerLocationChanged(location: ILatLong) {
        const newLoc = new FindAddressRequest();
        newLoc.location = location;
        newLoc.isAddressLoadRequired = true;
        this.newLocRequest = newLoc;
    }

    onChangeAddressClick() {
        this.displayChangeAddress = true;
        this.request.DvShipperCustomerFilter = this.context;
        this.currentAddress(this.request, false);
        if (this.isEditLocation) {
            this.setMarkerOptions(this.request.Latitude, this.request.Longitude);
        }
    }

    private currentAddress(request: SetLocationRequest, loadRequired: boolean) {
        const newLoc = new FindAddressRequest();
        newLoc.location = { latitude: request.Latitude, longitude: request.Longitude };
        newLoc.isAddressLoadRequired = loadRequired;
        newLoc.fullAddress = request.FullAddress;
        newLoc.AddressLine1 = request.Address1;
        newLoc.City = request.City;
        newLoc.StateDescription = request.State;
        newLoc.PostalCode = request.ZipCode;
        newLoc.CountryCode = request.Country;
        this.newLocRequest = newLoc;
    }

    onValidateLocation(args: ValidateLocationEventArgs) {
        this.displayChangeAddress = false;
        // this.changeAddressModalRef.hide();

        if (args.cancelUpdate) {
            if (this.isEditLocation) {
                this.showMarker(this.request);
            }
            return;
        }

        if (args.isUniqueLocation) {
            this.onAddressSelected(args.newLocation);
        } else if (args.shownearestLocations) {
            this.shownearestLocations = true;
            this.nearestLocations = args.nearestLocations;
            this.newLocationRequest = args.newLocRequest;
            this.setMarkerOptions(this.newLocationRequest.Latitude, this.newLocationRequest.Longitude);
        }
        else if (args.newLocationID) {
            this.addLocationSuccess(args.newLocationID, true);
        }
    }

    onUpdateMap(request: SetLocationRequest) {
        this.setMarker(+request.Latitude, +request.Longitude, false);
    }

    onEditNewLocation() {
        this.shownearestLocations = false;
        this.nearestLocations = [];
        this.selectedIndex = null;
        if (this.isFromManageLocations && this.newLocationRequest) {
            this.displayChangeAddress = true;
            if (this.isFromManageLocations) {
                this.setMarkerOptions(this.newLocationRequest.Latitude, this.newLocationRequest.Longitude);
            }
            this.currentAddress(this.newLocationRequest, false);
        }
    }

    onGeofenceRadiusChange() {
        this.disableSaveButton = true;
        if (this.request.RadiusKm && this.radiusKm && Number(this.radiusKm) === Number(this.request.RadiusKm)) {
            this.disableApplyButton = true;
            return;
        } else if (this.request.RadiusKm < 0) {
            this.disableApplyButton = true;
            return;
        } else {
            this.disableApplyButton = false;
        }
    }

    onDrawCircleClick(isInital = false) {
        if (isNaN(+this.request.RadiusKm)) {
            this.tempDrawOptions = null;
            return;
        }
        if (this.request.RadiusKm && this.radiusKm && Number(this.radiusKm) === Number(this.request.RadiusKm)) {
            this.disableApplyButton = true;
            this.disableSaveButton = true;
            return;
        }
        const location = {
            latitude: this.request.Latitude,
            longitude: this.request.Longitude
        };
        this.tempDrawOptions = {
            center: location,
            radius: +Number(this.request.RadiusKm),
            strokeColor: isInital ? 'blue' : 'green',
            polygonType: PolygonType.Circle,
            units: (this.distanceUnits.toLowerCase() === "miles" || this.distanceUnits.toLowerCase() === "m") ? DistanceUnits.Miles : DistanceUnits.Kilometers
        };
    }

    onGeofenceTypeChange() {
        this.disableApplyButton = false;
        this.disableSaveButton = true;
        this.request.Boundary = null;
        if (this.request.GeofenceType == 3) {
            this.request.RadiusKm = null;
        } else {
            this.request.RadiusKm = parseFloat(this.radiusKm);
        }

        const flagOptions: IMarkerOptions = {
            position: {
                latitude: 0,
                longitude: 0
            },
            icon: '/assets/images/flag_post.svg',
            anchor: { x: 2, y: 30 },
            draggable: false
        }
        this.tempDrawOptions = {
            strokeColor: 'green',
            polygonType: this.request.GeofenceType,
            flagOptions,
            units: (this.distanceUnits.toLowerCase() === "miles" || this.distanceUnits.toLowerCase() === "m") ? DistanceUnits.Miles : DistanceUnits.Kilometers
        };
    }

    onContinueClick() {
        if (this.isEditLocation) {
            if (this.selectedIndex) {
                let locationId = this.nearestLocations[this.selectedIndex - 1].LocationId;
                if (this.request.LocationId === locationId) {
                    // Do Nothing, Take back to Edit Screen
                    this.shownearestLocations = false;
                    this.nearestLocations = [];
                } else {
                    this.addLocationSuccess(locationId, true);
                }
            }
        }
        else if (this.selectedIndex > 0) {
            let locationId = this.nearestLocations[this.selectedIndex - 1].LocationId;
            let location = this.locationList.find(k => k.LocationId == locationId);
            // If selected location is both origin and destinaation then we don't need to do any action
            if (location?.IsOrigin && location?.IsDestination) {
                this.bsModalRef.hide();
                this.onAddLocation();
            }
            // If selected location type and requested location type is diffrent then need to update location type as both origin and destination 
            else if ((this.request.IsDestination && this.request.IsOrigin) || (!(location?.IsOrigin && this.request.IsOrigin) && !(location?.IsDestination && this.request.IsDestination))) {
                this.request.IsOrigin = true;
                this.request.IsDestination = true;
                this.request.CheckForDuplicates = false;
                this.request.Name = this.nearestLocations[this.selectedIndex - 1].LocationName;
                this.request.LocationId = this.nearestLocations[this.selectedIndex - 1].LocationId;
                const locationSubscription = this.locationSvc.createLocation(this.request, this.copiedFromCustomerId).subscribe(k => {
                    locationSubscription?.unsubscribe();
                    this.onAddLocation();
                });
                this.bsModalRef.hide();
            } else {
                this.bsModalRef.hide();
                this.onAddLocation();
            }
        }
        else {
            this.onAddLocation();
        }
    }

    onCoordinatesUpdate(e: PolygonEventArgs) {
        this.request.Boundary = null;
        if (e) {
            if (e.coordinates.length > 0) {
                if (!this.request.RadiusKm && e.coordinates?.length > this.maxFencePost && JSON.stringify(this.boundary) !== JSON.stringify(e.coordinates)) {
                    this.openErrorResponse(true, maximum_geo_fence, false, geo_fence)
                    return;
                }
                this.disableSaveButton = this.boundary === e.coordinates;
                if (this.request.GeofenceType !== this.fenceType.FlagPosts) {
                    this.setMapBox(this.request.Latitude, this.request.Longitude, e.coordinates);
                }
                this.request.Boundary = e.coordinates.map(n => {
                    return {
                        Latitude: n.latitude,
                        Longitude: n.longitude
                    }
                });
            }
        }
    }

    onSetGeoFenceClick() {
        if (this.validateLocation(this.request.Latitude, this.request.Longitude)) {
            return;
        }
        this.setGeofence = true;
        this.disableSaveButton = true;
        if (this.request.GeofenceType === undefined || this.request.GeofenceType === null) {
            this.request.GeofenceType = this.fenceType.FlagPosts;
        }
        this.onGeofenceTypeChange();
        this.showEditableFence = true;
    }

    onGeofenceBackClick() {
        this.setGeofence = false;
        this.showExistingFence = true;
        this.showEditableFence = true;
        this.onGeofenceTypeChange();
    }

    onDeleteGeofenceClick() {
        if (this.isEditLocation && this.setGeofence && this.request.LocationId) {
            let request = new DeleteGeofenceRequest();
            request.LocationId = this.request.LocationId;
            this.deleteGeofenceSubscription = this.locationSvc.deleteLocationGeofence(request).subscribe(
                n => {
                    if (n.ErrorCode !== 0) {
                        this.openErrorResponse(n.ErrorCode == 0 ? false : true, n.LocalizedErrorMessage,
                            n.ErrorCode == 0 ? true : false, label_delete_geofence);
                    }
                    else {
                        this.request.RadiusKm = null;
                        this.request.Boundary = null;
                        this.radiusKm = null;
                        this.boundary = [];
                        this.onClearGeofenceClick();
                        this.ResetSavedGeofence();
                    }
                },
                (e: IErrorInfo) => this.openErrorResponse(true, e.message, false, label_delete_geofence)
            );
        } else {
            this.ResetSavedGeofence();
            this.request.Boundary = null;
        }
    }

    openErrorResponse(hasError: boolean, errorMessage: string, closeOnOk: boolean, headerTitle: string, debugErrorMessage: string = null) {
        const bsModalRef = this.modalSvc.show(
            ErrorMessageResponseComponent,
            {
                initialState: {
                    hasError,
                    errorMessage,
                    closeOnOk,
                    debugErrorMessage,
                    sucessMessage: !hasError ? errorMessage : null,
                    headerTitle: headerTitle
                },
                class: 'modal-sm modal-dialog-centered',
                ignoreBackdropClick: true
            }
        );

        bsModalRef.content.ok.pipe(take(1)).subscribe(n => this.onResponseOk(n));
    }

    onResponseOk(args: EventArgs) {
        args.modalRef.hide();

        if (!args.hasError) {
            if (args.closeOnOk && this.bsModalRef) {
                this.bsModalRef.hide();
            } else {
                // Do Nothing
            }
        }
    }

    onClearGeofenceClick() {
        this.showEditableFence = true;
        this.onGeofenceTypeChange();
    }

    onSaveGeofenceClick() {
        if (this.isEditLocation && this.setGeofence && this.request.LocationId) {
            if (this.request.Boundary?.length <= 2) {
                this.openErrorResponse(true, "Invalid Location Geofence", false, label_save_geofence)
                return;
            }

            let request = new SetLocationRequest();
            request.LocationId = this.request.LocationId;
            request.Boundary = Number(this.request.RadiusKm) > 0 ? null : this.request.Boundary;
            request.RadiusKm = Number(this.request.RadiusKm) > 0 ? Number(this.request.RadiusKm) : null;
            this.saveGeofenceSubscription = this.locationSvc.saveLocationGeofence(request).subscribe(
                n => {
                    if (n.ErrorCode !== 0) {
                        this.openErrorResponse(n.ErrorCode == 0 ? false : true, n.LocalizedErrorMessage,
                            n.ErrorCode == 0 ? true : false, label_save_geofence);
                    }
                    else {
                        this.saveGeofenceSuccess();
                    }
                },
                (e: IErrorInfo) => this.openErrorResponse(true, e.message, false, label_save_geofence)
            );
        } else {
            this.saveGeofenceSuccess();
        }
    }

    private saveGeofenceSuccess() {
        this.boundary = this.request.Boundary.map(n => {
            return {
                latitude: n.Latitude,
                longitude: n.Longitude
            };
        });
        this.ExistingGeofence = true;
        this.showExistingFence = true;
        this.tempDrawOptions = null;
        this.radiusKm = Number(this.request.RadiusKm) > 0 ? this.request.RadiusKm.toString() : null;
        if (Number(this.request.RadiusKm) > 0) {
            const location = {
                latitude: this.request.Latitude,
                longitude: this.request.Longitude
            };
            this.drawOptions = {
                center: location,
                radius: +Number(this.request.RadiusKm),
                strokeColor: 'blue',
                polygonType: PolygonType.Circle,
                units: (this.distanceUnits.toLowerCase() === "miles" || this.distanceUnits.toLowerCase() === "m") ? DistanceUnits.Miles : DistanceUnits.Kilometers
            };
        } else {
            this.drawOptions = {
                strokeColor: 'blue',
                boundaryLocation: this.boundary,
                polygonType: PolygonType.FreeFlow
            };
        }
        this.onGeofenceBackClick();
    }
}
