import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy, Renderer2, Inject } from '@angular/core';
import { CustomTableColumnDefs, DistanceUnits, IBaseGeometryHelper, ILatLong, loadColumnDef, LocationInfo } from 'emr-ng-shared';
import { Observable, of, Subscription } from 'rxjs';
import {
    name, addressLine1, city, state, zip, Origin, Origin_Destination,
    Destination, label_delete_location, label_un_assign_location_contact,
    label_delete_location_confirm, label_yes, link_cancel, geoFence,
    label_geo_fence_overlapped, label_geo_fence_geo_overlapped
} from 'app-modules/core/consts/localization';
import { LocationService } from 'app-modules/core/services/location.service';
import { filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
    SetLocationRequest, DeleteLocationRequest, MapServiceFactory, CustomMapServiceFactory
} from 'emr-ng-shared';
import { AddLocationEventArgs, LocationComponent } from 'app-modules/core/components/location/location.component';
import { ShipmentDetailStateService } from 'app-modules/core/store/services/shipment-detail-state.service';
import { LocationContactComponent } from '../location-contact/location-contact.component';
import { LocationsExportService } from 'app-modules/core/services/location-export.service';
import { ConfirmModalComponent, ConfirmPopupEventArgs } from 'app-modules/core/components/confirm-modal/confirm-modal.component';
import { IErrorInfo } from 'app-modules/core/models/error-info.interface';
import { ErrorMessageResponseComponent } from 'app-modules/core/components/error-message-response/error-message-response.component';
import { LocationCollisionService } from 'app-modules/core/services/location-collisions.service';
import { PreferenceService } from 'app-modules/core/services/preference.service';
import { AuthService } from 'app-modules/core/store/auth/auth.service';

@Component({
    selector: 'manage-location',
    templateUrl: './manage-location.component.html',
    styleUrls: ['./manage-location.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class ManageLocationComponent implements OnInit, OnDestroy {
    columnDefs: CustomTableColumnDefs[];
    @ViewChild('button', { static: true }) button;
    @ViewChild('geoFence', { static: true }) geoFenceHTML;
    private locationListSubscription: Subscription;
    @ViewChild(NgForm) locationForm: NgForm;
    location: SetLocationRequest;
    error: string;
    locationType: string;
    bsModalRef: BsModalRef;
    locationTypes: any[];
    searchInput: string;
    PreviousSearchedText: string;
    showQuickReset = false;
    locationList: any[];
    locations: LocationInfo[];
    public showCopyFromLocations: boolean = false;
    locationData: LocationInfo[];
    loading$: Observable<boolean>;
    deleteLocationSub: Subscription;
    isLocusAdmin$: Observable<boolean> = of(false);

    searchedLocationId: number;
    isLocationExportInprogress$ = this.locationExportSvc.isDownloadInProgress$;
    showMapView: boolean = false;
    SelectedLocation: LocationInfo;
    GeometryHelper: IBaseGeometryHelper;
    locationsUpdated: boolean = false;
    preferenceSubscription: any;
    distanceUnits: DistanceUnits;
    ShowGeoFence: boolean = false;
    geoFenceCalculated: boolean = false;

    constructor(
        public locationSvc: LocationService,
        private modalSvc: BsModalService,
        private renderer: Renderer2,
        private locationExportSvc: LocationsExportService,
        private shipStateSvc: ShipmentDetailStateService,
        private prefSvc: PreferenceService,
        @Inject(MapServiceFactory) private mapSvc: CustomMapServiceFactory,
        private locationCollisionSvc: LocationCollisionService,
        private authSvc: AuthService) {
        this.renderer.addClass(document.body, 'bg-manage-location');
    }

    ngOnInit() {
        this.loadColumnDefinition();
        this.loading$ = this.locationSvc.isLoading$.pipe(map(k => {
            if (k) {
                this.shipStateSvc.loadExportSensorReport();
            } else {
                this.shipStateSvc.loadExportSensorReportSuccess();
            }
            return k;
        }));

        this.isLocusAdmin$ = this.authSvc.authState$.pipe(take(1)).pipe(map(au => {
            return au ? au.isEmersonAdmin : false;
        }));

        this.loadLocations();
        this.preferenceSubscription = this.prefSvc.preference$.pipe(take(1)).subscribe(n => {
            this.distanceUnits = (n?.distanceUnits?.toLowerCase() === "miles" || n?.distanceUnits?.toLowerCase() === "m") ? DistanceUnits.Miles : DistanceUnits.Kilometers;
        });
        this.locationTypes = [{ text: Origin, value: 'Origin' }, { text: Origin_Destination, value: 'Both' },
        { text: Destination, value: 'Destination' }]
    }

    loadLocations() {
        this.locationListSubscription = this.locationSvc.locationListIncludingCopy$.pipe(
            withLatestFrom(this.locationSvc.isLoadRequired$,
                this.locationSvc.isLoading$),
            filter(([k, lr, l]) => !lr && !l)
        ).subscribe(([k, lr, l]) => {
            const list = k.list.filter(x => this.showCopyFromLocations ? x.CopiedFromCustomerId > 0 : !x.CopiedFromCustomerId);
            this.locationList = (list).map(n => {
                let name: string = '';
                if (n.LocationName) { name = n.LocationName + ', ' }
                if (n.Address1) { name += n.Address1 + ', ' }
                if (n.City) { name += n.City + ', ' }
                if (n.State) { name += n.State + ', ' }
                if (n.Country) { name += n.Country + ', ' }
                if (n.Zip) { name += n.Zip }
                name = name?.trim();
                if (name?.lastIndexOf(',') === name?.length - 1) {
                    name = name.substring(0, name.length - 1)
                }
                return {
                    text: name,
                    value: n.LocationId
                }
            });
            this.locations = list;
            this.locationData = [...list];
            this.updateColumnCount(list);
        });
    }

    ngOnDestroy() {
        this.locationListSubscription?.unsubscribe();
        this.renderer.removeClass(document.body, 'bg-manage-location');
    }

    updateColumnCount(k) {
        if (k?.length > 0) {
            this.columnDefs.map(k => k.list = [], k.filteredList = [])
            k.map(a => {
                this.columnDefs.map(b => {
                    if (!b.isFilter) {
                        return b;
                    }
                    if (a[b.value] && a[b.valueField]?.toString()?.trim()?.length > 0) {
                        const listData = b.list.find(k => a[b.valueField]?.toString()?.toLowerCase() === k.value?.toLowerCase());
                        if (!listData) {
                            b.list.push({ text: a[b.value], value: a[b.valueField]?.toString(), count: 1, checked: false });
                        } else {
                            listData.count = listData.count + 1;
                        }
                        b.filteredList = b.list;
                    }
                });
            });
        } else {
            this.columnDefs.forEach(b => { b.list = []; });
        }

        this.columnDefs = [...this.columnDefs];
    }
    loadColumnDefinition() {
        this.columnDefs = [
            loadColumnDef({
                title: '', value: '', actionName: 'details', width: '120px',
                isSortable: false, isCustomHtml: true, customHTML: this.button, columnOrder: 1
            }),
            loadColumnDef({ title: name, value: 'LocationName', width: '300px', columnOrder: 2, isFilter: true }),
            loadColumnDef({
                title: geoFence, value: 'BoundaryFilterText', valueField: 'GeoFenceCollision', width: '100px',
                isSortable: true, columnOrder: 3, isFilter: true, nullValue: ''
            }),
            loadColumnDef({ title: addressLine1, value: 'Address1', width: '500px', columnOrder: 4, isFilter: true }),
            loadColumnDef({ title: city, value: 'City', width: '15%', isFilter: true, columnOrder: 5 }),
            loadColumnDef({ title: state, value: 'State', width: '15%', isFilter: true, columnOrder: 6 }),
            loadColumnDef({ title: zip, value: 'Zip', width: '10%', columnOrder: 7, isFilter: true }),
        ];
    }

    // Add New Location
    openAddLocationModal() {
        this.openLocationModal(null, new SetLocationRequest());
    }

    onLocationEdit(data) {
        if (data.IsOrigin && data.IsDestination) {
            this.locationType = 'Both';
        } else if (data.IsOrigin) {
            this.locationType = 'Origin';
        } else if (data.IsDestination) {
            this.locationType = 'Destination';
        }

        if (!data?.BoundaryLoaded) {
            this.locationSvc.getLocationBoundary({ LocationId: data.LocationId })
                .pipe(take(1))
                .subscribe(n => {
                    data.Boundary = n?.LocationInfo?.Boundary ?? [];
                    data.RadiusKm = n?.LocationInfo?.RadiusKm
                    this.openSetLocationPopup(data);
                },
                    e => {
                        this.openSetLocationPopup(data);
                    });
        } else {
            this.openSetLocationPopup(data);
        }
    }

    private openSetLocationPopup(data: any) {
        let boundary: ILatLong[] = [];
        data?.Boundary?.forEach(b => {
            boundary.push({ latitude: b.Latitude, longitude: b.Longitude });
        });

        this.location = { ...data };
        this.location.Name = data.LocationName;
        this.location.ZipCode = data.Zip;
        this.location.TempTripTriggerDistanceKm = data?.TripTriggerDistanceKm;
        this.location.TripTriggerDistanceKm = data?.TripTriggerDistanceKm <= +0 ? null : data?.TripTriggerDistanceKm;
        this.location.ShipperCustomerID = data.LinkedCustomerId;
        this.location.LinkedCustomerId = null;
        this.openLocationModal(boundary, this.location, true, data?.CopiedFromCustomerId);
    }

    private openLocationModal(boundary: ILatLong[], location: SetLocationRequest, isEditLocation = false, copiedFromCustomerId: Number = null) {
        if (location.RadiusKm) {
            location.GeofenceType = 0;
        }
        this.bsModalRef = this.modalSvc.show(
            LocationComponent,
            {
                initialState: {
                    locationTypes: this.locationTypes,
                    request: location,
                    locationType: isEditLocation ? this.locationType : null,
                    isEditLocation: isEditLocation,
                    boundary: boundary,
                    radiusKm: location.RadiusKm,
                    isFromManageLocations: true,
                    copiedFromCustomerId: copiedFromCustomerId
                },
                class: 'modal-md modal-dialog-centered max-width-1300',
                ignoreBackdropClick: true
            });
        this.bsModalRef.content.addLocation.pipe(take(1)).subscribe(n => this.onEditLocation(n, isEditLocation));
    }

    onEditLocation(args: AddLocationEventArgs, isEditLocation: boolean) {
        this.bsModalRef.hide();

        // Reset previous values
        this.locationType = null;
        this.location = null;

        if (!isEditLocation && args?.request?.LocationId) {
            let locationData = this.locationData.find(l => l.LocationId === args.request.LocationId);
            if (locationData) {
                this.searchInput = locationData.LocationName;
                this.checkForLocationInLocal(args.request.LocationId);
                this.showQuickReset = true;
            }
        }
    }

    //#region Quick search related Functionality Start
    onSerialNameSubmit() {
        this.searchedLocationId = null;
        this.searchInput = this.searchInput ? this.searchInput.trim() : this.searchInput;
        if (this.PreviousSearchedText !== this.searchInput) {
            this.PreviousSearchedText = this.searchInput;
            this.checkForLocationInLocal(this.locationList.find(k => k.text === this.searchInput)?.value);
        }
        this.showQuickReset = true;
    }

    onSearchInputSelect(data: any) {
        this.searchedLocationId = null;
        if (this.PreviousSearchedText !== data.item.value) {
            this.PreviousSearchedText = data.item.text;
            this.searchInput = data.item.text;
            this.checkForLocationInLocal(data.item.value);
            this.showQuickReset = true;
        }
    }

    checkForLocationInLocal(Id: number) {
        if (!Id) {
            return;
        }
        this.searchedLocationId = Id;
        this.locations = this.locationData.filter(k => k.LocationId === Id);
        this.updateColumnCount(this.locations)
    }

    resetQuickSearch() {
        this.searchedLocationId = null;
        this.searchInput = this.PreviousSearchedText = null;
        this.showQuickReset = false;
        if (this.locationData?.length) {
            this.locations = [...this.locationData];
            this.updateColumnCount(this.locations);
        }
    }

    customBlur() {
        this.searchInput = null;
        this.resetQuickSearch();
    }
    //#endregion Quick search related Functionality End

    onFilterApply(col: CustomTableColumnDefs) {
    }


    // Location Contacts Start

    onSetLocationContact(data) {
        this.openLocationContactModal(data);
    }

    private openLocationContactModal(location: LocationInfo) {
        const bsLocContactModalRef = this.modalSvc.show(
            LocationContactComponent,
            {
                initialState: {
                    locationID: location.LocationId,
                },
                class: 'modal-md modal-dialog-centered max-width-1300',
                ignoreBackdropClick: true
            });
    }

    // Location Contacts End

    onLocationsExport() {
        let locationIds = this.locationData.length != this.locations.length ? this.locations.map(x=>x.LocationId).join(',') : "";
        this.locationExportSvc.excelExport(this.showCopyFromLocations, locationIds);
    }


    onLocationDelete(location: LocationInfo) {
        const bsDeleteModalRef = this.modalSvc.show(
            ConfirmModalComponent,
            {
                initialState: {
                    headerTitle: label_delete_location,
                    subHeaderTitle: location.LocationName,
                    showCloseButton: true,
                    confirmMessage: label_delete_location_confirm,
                    yesText: label_yes,
                    noText: link_cancel,
                },
                class: 'modal-md modal-dialog-centered max-width-350 report-max-height',
                ignoreBackdropClick: true
            }
        );

        bsDeleteModalRef.content.okCancelClick.pipe(take(1)).subscribe(n => this.onOKClick(n, location));
    }

    onOKClick(args: ConfirmPopupEventArgs, location: LocationInfo) {
        setTimeout(() => {
            switch (args.button) {
                case "Yes":
                    this.deleteLocation(location);
                    break;
                case "No":
                    // Do Nothing
                    break;
            }
        }, 30);
        args.modalRef.hide();
    }

    deleteLocation(location: LocationInfo) {
        let deleterequest = new DeleteLocationRequest();
        deleterequest.LocationId = location?.LocationId;
        deleterequest.LocationName = location?.LocationName;
        if (deleterequest.LocationId) {
            this.deleteLocationSub = this.locationSvc.deleteLocation(deleterequest).subscribe(
                n => {
                    if (n.ErrorCode !== 0) {
                        this.openErrorResponse(n.ErrorCode == 0 ? false : true, n.LocalizedErrorMessage,
                            n.ErrorCode == 0 ? true : false, label_delete_location);
                    }
                },
                (e: IErrorInfo) => this.openErrorResponse(true, e.message, false, label_delete_location)
            );
        }
    }

    openErrorResponse(hasError: boolean, errorMessage: string, closeOnOk: boolean, headerTitle: string, debugErrorMessage: string = null) {
        const bsModalRef = this.modalSvc.show(
            ErrorMessageResponseComponent,
            {
                initialState: {
                    hasError,
                    errorMessage,
                    closeOnOk,
                    debugErrorMessage,
                    sucessMessage: !hasError ? errorMessage : null,
                    headerTitle: headerTitle
                },
                class: 'modal-sm modal-dialog-centered',
                ignoreBackdropClick: true
            }
        );
    }
    onRowClick(e: LocationInfo) {
        this.SelectedLocation = null;
        if (this.ShowGeoFence)
            this.SelectedLocation = e;
    }

    public updateLocations(locs: LocationInfo[] = this.locations) {
        this.SelectedLocation = null;
        if (this.ShowGeoFence && !this.geoFenceCalculated) {
            this.shipStateSvc.loadExportSensorReport();
            setTimeout(() => {
                this.geoFenceCalculated = true;
                this.locationData = this.locationCollisionSvc.updateLocationCollisions(locs, this.distanceUnits);
                if (this.searchInput) {
                    this.locations = [];
                    this.checkForLocationInLocal(this.locationList.find(k => this.searchedLocationId ? k.value === this.searchedLocationId : k.text.toLowerCase() === this.searchInput)?.value);
                } else {
                    this.locations = this.locationData;
                }
                this.updateColumnCount(this.locations);
                if (this.SelectedLocation) {
                    const newLocObj = this.locations?.find(l => l.LocationId === this.SelectedLocation.LocationId);
                    this.SelectedLocation = newLocObj;
                }
                this.shipStateSvc.loadExportSensorReportSuccess();
            }, 200)
        }
    }

    loadCopiedLocations() {
        this.customBlur();
        this.loadLocations();
    }
}
